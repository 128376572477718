import React, { JSX, ReactNode } from 'react'
import Icon from '@/components/commons/icon'

export default function FeatureSection(): JSX.Element {
  const features: {
    name: string
    description: string
    icon: ReactNode
  }[] = [
    {
      name: 'Unlimited storage',
      description:
        'Store as many posts, images, and videos as you want without worrying about running out of space.',
      icon: (
        <Icon
          name="CloudUpload"
          className="h-6 w-6 text-white"
          aria-hidden="true"
        />
      ),
    },
    {
      name: 'Mobile optimization',
      description:
        'Ensure your blog looks great and performs well on all mobile devices with our automatic optimization.',
      icon: (
        <Icon
          name="TabletSmartphone"
          className="h-6 w-6 text-white"
          aria-hidden="true"
        />
      ),
    },
    {
      name: 'Custom domain',
      description:
        'Enhance your brand with a custom domain name that makes your blog easily recognizable and professional.',
      icon: (
        <Icon name="Atom" className="h-6 w-6 text-white" aria-hidden="true" />
      ),
    },
    {
      name: 'Team members',
      description:
        'Collaborate with your team members and assign roles and permissions to manage your blog effectively.',
      icon: (
        <Icon name="Users" className="h-6 w-6 text-white" aria-hidden="true" />
      ),
    },
    {
      name: 'Artificial intelligence',
      description:
        'Leverage AI to optimize your content and improve your blog’s performance with our smart recommendations.',
      icon: (
        <Icon name="Brain" className="h-6 w-6 text-white" aria-hidden="true" />
      ),
    },
    {
      name: '24/7 support',
      description:
        'Get help whenever you need it with our round-the-clock customer support team ready to assist you.',
      icon: (
        <Icon
          name="LifeBuoy"
          className="h-6 w-6 text-white"
          aria-hidden="true"
        />
      ),
    },
  ]

  return (
    <div className="mx-auto max-w-7xl px-6 lg:px-8">
      <div className="mx-auto max-w-2xl lg:text-center">
        <h2 className="text-base font-semibold leading-7 text-pink-600">
          Start Your Blogging Journey
        </h2>
        <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
          Everything you need to make your blog successful
        </p>
        <p className="mt-6 text-lg leading-8 text-gray-600">
          Empower your voice and engage your audience with our comprehensive
          blog tools and features. From SEO optimization to social media
          integration, we provide all the resources you need to build a thriving
          online presence. Customize your blog with beautiful themes, track your
          performance with detailed analytics, and enjoy seamless content
          creation with our intuitive editor.
        </p>
      </div>
      <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
        <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
          {features.map(
            (feature: {
              name: string
              description: string
              icon: ReactNode
            }) => (
              <div key={feature.name} className="relative pl-16">
                <dt className="text-base font-semibold leading-7 text-gray-900">
                  <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-pink-600">
                    {feature.icon}
                  </div>
                  {feature.name}
                </dt>
                <dd className="mt-2 text-base leading-7 text-gray-600">
                  {feature.description}
                </dd>
              </div>
            )
          )}
        </dl>
      </div>
    </div>
  )
}
